import React from 'react';
import Layout from '@components/Layout';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';

const styles = {
    paddingTop: '16px',
    paddingBottom: '16px',
    background: 'rgba(86,61,124,.15)',
    border: '1px solid rgba(86,61,124,.2)',
};

const HomePage = () => (
    <Layout>
        <Grid>
            <Text>Default Columns</Text>
            <br />
            <Row>
                <Column style={styles}>col</Column>
                <Column style={styles}>col</Column>
            </Row>
        </Grid>
        <br />
        <Grid>
            <Text>Columns</Text>
            <br />
            <Row>
                <Column style={styles} col="1">
                    col 1
                </Column>
                <Column style={styles} col="2">
                    col 2
                </Column>
                <Column style={styles} col="1">
                    col 1
                </Column>
                <Column style={styles} col="4">
                    col 4
                </Column>
                <Column style={styles} col="3">
                    col 3
                </Column>
                <Column style={styles} col="1">
                    col 1
                </Column>
            </Row>
        </Grid>
        <br />
        <Grid>
            <Text>Variable columns</Text>
            <br />
            <Row>
                <Column style={styles} col="2" sm="4" md="6" l="4" xl="3">
                    col-2 sm-4 md-6 l-4 xl-3
                </Column>
                <Column style={styles} col="2" sm="4" md="6" l="8" xl="3">
                    col-2 sm-4 md-6 l-8 xl-3
                </Column>
                <Column style={styles} col="1" sm="2" md="4" l="3" xl="3">
                    col-1 sm-2 md-4 l-3 xl-3
                </Column>
                <Column style={styles} col="3" sm="2" md="8" l="3" xl="3">
                    col-3 sm-2 md-8 l-3 xl-3
                </Column>
                <Column style={styles} col="1" sm="2" md="8" l="3" xl="12">
                    col-1 sm-2 md-8 l-3 xl-12
                </Column>
                <Column style={styles} col="1" sm="2" md="4" l="3" xl="2">
                    col-1sm-2 md-4 l-3 xl-2
                </Column>
                <Column style={styles} col="1" sm="3" md="3" l="6" xl="8">
                    col-1sm-3 md-3 l-6 xl-8
                </Column>
                <Column style={styles} col="1" sm="5" md="9" l="6" xl="2">
                    col-1 sm-5 md-9 l-6 xl-2
                </Column>
            </Row>
        </Grid>
        <br />
        <Grid>
            <Text>Offset columns</Text>
            <br />
            <Row>
                <Column style={styles} offset="2" col="2">
                    col-2 offset-2
                </Column>
                <Column style={styles} offset="3" col="1">
                    col-1 offset-3
                </Column>
                <Column style={styles} offset="1" col="2">
                    col-1 offset-1
                </Column>
            </Row>
        </Grid>
        <br />
        <Grid>
            <Text>Variable offset columns</Text>
            <br />
            <Row>
                <Column
                    style={styles}
                    offset="2"
                    offsetSm="3"
                    offsetMd="10"
                    offsetL="6"
                    offsetXl="2"
                    sm="5"
                    md="2"
                    l="3"
                    xl="10"
                >
                    offset-2 offset-sm-3 offset-md-10 offset-l-6 offset-xl-2
                </Column>
            </Row>
        </Grid>
    </Layout>
);

export default HomePage;
